/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/** @jsx jsx */
import {useState} from 'react'
import {graphql} from 'gatsby'
import {jsx, Styled} from 'theme-ui'
import {loadStripe} from '@stripe/stripe-js'
import {Layout, SEO, Contact} from '../components'
import {ListItem} from '../components/Listing'
import {Container, BuchCarousel, PostsWrapper} from '../elements'
import {Star, PlusBtn, MinusBtn} from '../images/icons'
import Navigation from '../components/navigation'

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      'pk_live_51HOglLJcWTRDu3tEi8rpD6lVq1xQ7n3logUgrtNp9K4smghf5Lvlj50SNnDJuXOWiWwincoJEakoG856eZ5MW93800GqvZb49t'
    )
  }
  return stripePromise
}

const BookSite = ({
  data: {Buch, BookPrice, prismicForm, allEvents, i18nPathname},
  location,
}) => {
  const {lang} = prismicForm
  const [loading, setLoading] = useState(false)
  const [bookQuantity, setQuantity] = useState(1)

  const redirectToCheckout = async (event) => {
    event.preventDefault()
    setLoading(true)
    const stripe = await getStripe()
    const {error} = await stripe.redirectToCheckout({
      lineItems: [{price: BookPrice.id, quantity: bookQuantity}],
      mode: 'payment',
      successUrl: `https://pugatsch.ch/erfolgreich`,
      cancelUrl: `https://pugatsch.ch/abgesagt`,
      shippingAddressCollection: {
        allowedCountries: ['CH'],
      },
    })
    if (error) {
      console.warn('Error:', error)
      setLoading(false)
    }
  }

  return (
    <Layout customSEO>
      <Navigation customNav lang={lang} i18nPathname={i18nPathname.nodes} />
      <SEO data={Buch.data} pathname={location.pathname} />
      <Container type="big">
        <div
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            pt: ['5rem', '', '8rem'],
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            alignContent: 'flex-start',
            marginBottom: '4rem',
            maxWidth: '1200px',
            margin: '0 auto',
          }}
        >
          <div
            sx={{
              flexBasis: 'calc(99.9% * 2 / 5 - 1rem)',
              maxWidth: 'calc(99.9% * 2 / 5 - 1rem)',
              width: 'calc(99.9% * 2 / 5 - 1rem)',
              '@media (max-width: 800px)': {
                flexBasis: '80%',
                maxWidth: '80%',
                width: '80%',
                m: '0 auto',
                // mb: '2rem',
                textAlign: 'center',
              },
            }}
          >
            <BuchCarousel gallery={Buch.data.book_carousel} />
          </div>

          <div
            sx={{
              flexBasis: 'calc(99.9% * 3 / 5 - 1rem)',
              maxWidth: 'calc(99.9% * 3 / 5 - 1rem)',
              width: 'calc(99.9% * 3 / 5 - 1rem)',
              '@media (max-width: 800px)': {
                flexBasis: '100%',
                maxWidth: '100%',
                width: '100%',
                mt: '-4rem',
              },
            }}
          >
            <h2
              sx={{
                color: 'primary',
                p: 0,
                m: 0,
                fontWeight: 'normal',
                fontSize: '1.3rem',
                lineHeight: 1.1,
                pb: '0.4rem',
              }}
            >
              Dalia Pugatsch
            </h2>
            <h1
              sx={{
                fontSize: '2.5rem',
                lineHeight: 1.1,
                p: 0,
                m: 0,
              }}
            >
              {Buch.data.title}
            </h1>

            <div
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                pt: '1rem',
              }}
            >
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
            </div>

            <h3
              sx={{
                fontSize: '1.2rem',
                fontWeight: 'bold',
                mb: 0,
              }}
            >
              {Buch.data.subtitle}
            </h3>
            <div dangerouslySetInnerHTML={{__html: Buch.data.desc.html}} />

            <div
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                '@media (max-width: 600px)': {
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                },
              }}
            >
              <div
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <span
                  sx={{
                    pt: '1.5rem',
                    pb: '0.5rem',
                  }}
                >
                  Menge
                </span>
                <div
                  sx={{
                    display: 'flex',
                    flexWrap: 'auto',
                    alignItems: 'center',
                    pr: '1rem',
                    minHeight: '40px',
                    svg: {
                      height: '30px',
                      width: '30px',
                    },
                  }}
                >
                  <span
                    sx={{
                      fontSize: '2rem',
                      lineHeight: 1.2,
                      fontWeight: 600,
                      mr: '0.25rem',
                      color: (t) => t.colors.gray[8],
                    }}
                  >
                    {bookQuantity}
                  </span>{' '}
                  <a
                    sx={{
                      role: 'button',
                      height: '31px',
                      width: '31px',
                      cursor: 'pointer',
                      m: '0 0.8rem',
                      svg: {
                        height: '100%',
                        width: '100%',
                      },
                    }}
                    onClick={() => setQuantity(bookQuantity + 1)}
                  >
                    <PlusBtn />
                  </a>
                  {bookQuantity > 1 && (
                    <a
                      sx={{
                        role: 'button',
                        height: '30px',
                        width: '30px',
                        cursor: 'pointer',
                        pr: 2,
                      }}
                      onClick={() => setQuantity(bookQuantity - 1)}
                    >
                      <MinusBtn />
                    </a>
                  )}
                </div>

                <span
                  sx={{
                    lineHeight: 0.5,
                    pt: '1.5rem',
                  }}
                >
                  Preis
                </span>
                <strong
                  sx={{
                    fontSize: '1.5rem',
                    color: 'primary',
                    pr: 4,
                  }}
                >
                  {(BookPrice.unit_amount * bookQuantity) / 100}{' '}
                  {BookPrice.currency.toUpperCase()}
                </strong>
                <span sx={{fontSize: '0.8rem'}}>{Buch.data.preisnotizen}</span>
              </div>
              <button
                disabled={loading}
                onClick={redirectToCheckout}
                type="button"
                sx={{
                  variant: 'buttons.primary',
                  boxShadow: '0 12px 18px rgba(237, 137, 54, 0.16)',
                  fontSize: '1.5rem',
                  '@media (max-width: 600px)': {
                    mt: '1rem',
                    fontSize: '1.25rem',
                  },
                }}
              >
                {Buch.data.cta_btn}
              </button>
            </div>
          </div>
        </div>
        <div
          sx={{
            mt: ['4rem', '', '8rem'],
          }}
        >
          <Styled.h1>{Buch.data.pd_title}</Styled.h1>
          <div dangerouslySetInnerHTML={{__html: Buch.data.desc_cont.html}} />
        </div>
      </Container>

      {/* If there's events data */}
      {allEvents.nodes.length > 0 && (
        <Container>
          <Styled.h1
            sx={{
              mb: [0, '', '1rem'],
            }}
          >
            Kommende Veranstaltungen
          </Styled.h1>
          <PostsWrapper>
            {allEvents.nodes.map((item) => (
              <ListItem
                img={item.data.image.localFile.childImageSharp.fluid}
                capTitle={item.data.cb_title}
                date={item.data.event_date}
                path={`/veranstaltungen/${item.uid}`}
                isFuture={item.isFuture}
              />
            ))}
          </PostsWrapper>
        </Container>
      )}

      <Contact data={prismicForm} lang={lang} />
    </Layout>
  )
}

export const query = graphql`
  query Buch($lang: String) {
    i18nPathname: allPrismicBuch {
      nodes {
        uid
        lang
      }
    }

    Buch: prismicBuch(lang: {eq: $lang}) {
      uid
      lang
      data {
        book_carousel {
          image {
            url
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        title
        subtitle
        desc {
          html
        }
        bottom_txt
        price
        preisnotizen
        cta_btn

        pd_title
        desc_cont {
          html
        }

        seo_title
        seo_description
        seo_image {
          url
        }
      }
    }
    BookPrice: stripePrice(id: {eq: "price_1HWeftJcWTRDu3tEQ3m0Y3va"}) {
      id
      unit_amount
      currency
    }

    prismicForm(lang: {eq: $lang}) {
      lang
      ...FormInformation
    }

    allEvents: allPrismicEvents(
      sort: {fields: data___event_date, order: ASC}
      filter: {isFuture: {eq: true}}
      limit: 2
    ) {
      nodes {
        ...EventPost
      }
    }
  }
`

export default BookSite
